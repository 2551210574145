import _ from 'lodash';
import React from 'react';

import Axios from 'axios';
import PropTypes from 'prop-types';

import { ApplicationContext } from '../../context';

let timer = null;

const ApplicationContextProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [tries, setTries] = React.useState(0);
  const [publication, setPublication] = React.useState(null);
  const [webdev, setWebdev] = React.useState(window.webdev === true || false);
  const [affichageDefaut, setAffichageDefaut] = React.useState({});
  const [preprod, setPreprod] = React.useState(false);
  const [identifiantEntite, setIdentifiantEntite] = React.useState(0);
  const [salles, setSalles] = React.useState('');
  const [modeTest, setModeTest] = React.useState(false);
  const [baseUrl, setBaseUrl] = React.useState('');
  console.log('baseUrl', baseUrl);

  React.useEffect(() => {
    console.log('use Effect ApplicationContextProvider');
    console.log('loading', loading);
    if (loading) {
      console.log('window.affichageDefaut', window.affichageDefaut);
      if (_.isNil(window.affichageDefaut)) {
        console.log('on timeout');
        timer = setTimeout(() => {
          setTries((t) => t + 1);
        }, 300);
      } else {
        console.log('on affecte les valeurs');
        clearTimeout(timer);
        setPublication(window.publication);
        setWebdev(window.webdev);
        setAffichageDefaut(window.affichageDefaut);
        setPreprod(window.pp);
        setIdentifiantEntite(window.id_entite);
        setSalles(window.id_Salle);
        setModeTest(window.modeTest);
        setLoading(false);

        setBaseUrl(process.env.NODE_ENV === 'development'
          ? 'http://127.0.0.1:8000'
          : (window.webdev && window.modeTest)
            ? 'http://localhost:81'
            : window.pp
              ? 'https://planning.qa.jvs-mairistem.dev'
              : 'https://planning-gds.jvsonline.fr');

        // timeout des requêtes
        Axios.defaults.timeout = process.env.NODE_ENV === 'development'
          ? 120000 // 120s en dev
          : 30000; // 30s en prod
        Axios.interceptors.request.use(
          (request) => {
            if (!_.isNil(window.publication)) {
              request.headers['X-Database'] = window.publication;
            }

            return request;
          },
        );
      }
    }
  }, [loading, tries]);

  const contextValue = React.useMemo(
    () => ({
      publication,
      webdev,
      affichageDefaut,
      loading,
      preprod,
      identifiantEntite,
      salles,
      modeTest,
      baseUrl,
    }),
    [
      publication,
      webdev,
      affichageDefaut,
      loading,
      preprod,
      identifiantEntite,
      salles,
      modeTest,
      baseUrl,
    ],
  );

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  );
};

ApplicationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApplicationContextProvider;
